import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../templates/layout";

const listenLinks = [
  {
    url: "https://open.spotify.com/show/10b0QbWL5i5lmNlXDO0PtT?si=ycMhyCmkTgKkTfh1mD-l3g",
    site: "Spotify"
  },
  {
    url: "https://itunes.apple.com/us/podcast/renegade-radio/id1257425556?mt=2",
    site: "iTunes"
  },
  {
    url: "https://playmusic.app.goo.gl/?ibi=com.google.PlayMusic&isi=691797987&ius=googleplaymusic&apn=com.google.android.music&link=https://play.google.com/music/m/Ib22bdgvptd7uwdj2mmnystvvli?t%3DWithout_Boxes_Podcast%26pcampaignid%3DMKT-na-all-co-pr-mu-pod-16",
    site: "Google Play"
  }
]

const IndexPage = ({ data }) => {
  const { nodes } = data.allPodcast
  let seasons = {
    2019: {
      title: "Season 3",
      description: [],
      takeaways: [],
      episodes: []
    },
    2018: {
      title: "Season 2",
      description: [
        "Our first season was hectic, but we found such inspiration in the folks we were talking to, we knew we had to go a second round. So, we reached out to collect even more stories and life lessons from the people you’d least expect to hear about – and we were not disappointed.",
        "From a trans-woman cycling the entire Pan-American highway alone to a woman who moved internationally solo on a cargo boat, Season 2 is full of twists and turns. We hope it sparks ideas for what is possible in your own life!"
      ],
      takeaways: [
        "The world tends to shove preset ideas at us about who we are supposed to be. But what happens when you don’t fit that mold?",
        "It’s possible to dig deep and find your authentic self; identify your true essentials. You can contribute to a movement and build a strong community around you.",
        "The time to start is now."
      ],
      episodes: []
    },
    2017: {
      title: "Season 1",
      description: [
        "Our pilot season launched in July 2017. Hitting publish was both terrifying and exciting – neither of us had run a podcast before.",
        "The mission was simple: Highlight the stories of ordinary people doing extraordinary things, in hopes to inspire others to do the same in their own lives.",
        "In this season, you’ll hear about digital nomads, self-care in activism, and much more."
      ],
      takeaways: [
        "Life is unpredictable. You can fight it, or learn to accept the unknown and learn to navigate the flow. By identifying your foundation and taking action, you’ll be presented with new opportunities and chances to build a life you love.",
        "All the while, you’ll get the chance to improve the lives around you.",
        "What are you waiting for?"
      ],
      episodes: []
    },
  }
  for (let i = 0; i < nodes[0].item___link.length; i++) {
    let title = nodes[0].item___title[i];
    let url = nodes[0].item___link[i];
    let date = nodes[0].item___pubDate[i];
    let summary = nodes[0].itunes_summary[i];
    date = new Date(date);
    let year = date.getFullYear()
    let episode = {
      title: title,
      embed: url.replace('/s/', '/e/'),
      date: date,
      summary: summary
    }
    seasons[year].episodes.push(episode)
  }
  return (
    <Layout>
      <main>
        <div className="pt-10 text-center">
          <h1 className="font-extrabold text-5xl md:text-6xl leading-tighter tracking-tight mb-2">Without Boxes</h1>
          <p className="font-serif text-xl text-olive-400">Ordinary people. Extraordinary stories.</p>
          <p className="mt-4 px-8 lg:px-0">Available to listen on {listenLinks.map(link => {
            return (
              <a key={link.url} href={link.url} className="underline decoration-coral-600 underline-offset-4 transition-all ease-in-out duration-300 hover:text-coral-600">{link.site}</a>
            )
          }).reduce((prev, curr) => [prev, ', ', curr])}.</p>
        </div>
        <div className="mt-10 py-10 bg-coral-600 text-white">
          <p className="text-center font-light lg:text-2xl max-w-3xl mx-auto font-serif lg:px-0 px-6">Millions of people are out there chasing passions, starting projects, and saying no to the status quo. These people are our neighbors, friends, family: ordinary people doing extraordinary things. We enable folks to share their stories and advice, helping audiences around the world get inspired to chase their own passions. We’re breaking barriers and inspiring dreamers, one story at a time.</p>
        </div>
        <div className="mt-2 max-w-5xl mx-auto px-6 lg:px-0">
          {Object.keys(seasons).reverse().map((keyName, i) => {
            const season = seasons[keyName]
            return (
              <div key={keyName} className="mt-10">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 mt-24 mb-10">
                  <div>
                    <h2 className="font-bold text-3xl font-serif mb-6">{keyName} · {season.title}</h2>
                    {season.description && season.description.map(p => (
                      <p key={p} className="text-lg mb-2">{p}</p>
                    ))}
                  </div>
                  {season.takeaways.length > 0 && <div className="p-6 border rounded-md mb-5">
                    <h3 className="font-bold text-2xl font-serif mb-3">{season.title} Takeaways</h3>
                    {season.takeaways.map(p => (
                      <p key={p} className="mb-2">{p}</p>
                    ))}
                  </div>
                  }
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12">
                  {season.episodes.map(ep => {
                    return (
                      <div key={ep.date} className="mb-5">
                        <iframe title={ep.date} width="100%" height="180" frameBorder="no" scrolling="no" seamless src={ep.embed}></iframe>
                        <div className="p-4">
                          <h4 className="text-olive-400 font-bold mb-2 text-xl font-serif">{ep.title}</h4>
                          <p className="text-sm mb-4">{ep.summary.substring(0, 300)}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </main>
    </Layout >
  )
}

export default IndexPage

export const Head = () => <title>Without Boxes</title>

export const podcastQuery = graphql`
query PodcastQuery {
  allPodcast {
    nodes {
      item___link
      item___pubDate
      item___title
      itunes_summary
    }
  }
}
`